<template>
    <div v-if="!loading" class="CS-form" id="CS-form">
        <FormulateForm v-if="!started && !finished" @submit="startQuiz" class="form">
            <h4 class="form-title">{{ baseData.title[currentLanguage] }}</h4>
            <span class="form-text" v-html="baseData.text[currentLanguage]"></span>
            <div class="email-input-wrapper input-wrapper">
                <FormulateInput 
                    type="email" 
                    name="email"        
                    :placeholder="labels.enter_email_please"
                    validation="required|email" 
                    error-behavior="blur" 
                />
            </div>
            <FormulateInput class="submit-button" type="submit" :label="labels.start" />
        </FormulateForm>
        <template v-if="started && !finished">
            <FormulateForm class="form-to-fill" @submit.prevent>
                <div
                    v-for="item, key in dataContent"
                    :key="key"
                    class="form-item"
                >
                    <!-- Paragraph -->
                    <template v-if="item.value.type === 'paragraph'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="text-input-wrapper input-wrapper">
                            <FormulateInput 
                                type="text"
                                :placeholder="labels.answer" 
                                :validation="item.value.required ? 'required' : ''"
                                @input="dataChanged($event, key)"/>
                        </div>
                    </template>
                    <!-- Grid -->
                    <template v-if="item.value.type === 'grid'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                            <div class="table-wrapper">
                                <table class="table grid-table">
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <th v-for="(column, key) in item.value.options.column" :key="key"><span>{{ column }}</span></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="item.value.options.type === 'checkbox'">
                                        <tr v-for="(row, rowKey) in item.value.options.row" :key="rowKey">
                                            <th><span>{{row}}</span></th>
                                            <td v-for="(column, columnKey) in item.value.options.column" :key="columnKey" class="td-white">
                                                <FormulateInput
                                                    type="checkbox"
                                                    v-model="gridTableValues[`${rowKey}$${columnKey}`]"
                                                    @change="gridChanged( 
                                                        {rowKey:rowKey, 
                                                        columnKey:columnKey, 
                                                        rowDependence:item.value.options.row_dependence, 
                                                        inputType:item.value.options.type
                                                        }, 
                                                        key 
                                                    )"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else-if="item.value.options.type === 'radio'">
                                        <tr v-for="(row, rowKey) in item.value.options.row" :key="rowKey">
                                            <th><span>{{row}}</span></th>
                                            <td v-for="(column, columnKey) in item.value.options.column" :key="columnKey" class="td-white">
                                                <FormulateInput
                                                    type="radio"
                                                    class="radio-input"
                                                    :name="item.value.options.row_dependence ? rowKey : columnKey"
                                                    :checked="item.value.options.row_dependence ? gridTableValues[rowKey] === columnKey : gridTableValues[columnKey] === rowKey"
                                                    @change="gridChanged( 
                                                        {rowKey:rowKey, 
                                                        columnKey:columnKey, 
                                                        rowDependence:item.value.options.row_dependence, 
                                                        inputType:item.value.options.type
                                                        }, 
                                                        key 
                                                    )"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                    </template>
                    <!-- Richtext -->
                    <template v-if="item.value.type === 'richtext'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="text-area-wrapper input-wrapper">
                            <FormulateInput
                                type="textarea"
                                :validation="item.value.required ? 'required' : ''"
                                @input="dataChanged($event, key)" 
                            />
                        </div>
                    </template>
                    <!-- Select -->
                    <template v-if="item.value.type === 'select'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="select-wrapper input-wrapper">
                            <FormulateInput
                                v-if="item.value.options.type === 'checkbox'"
                                type="checkbox"
                                :validation="item.value.required ? 'required' : ''"
                                :options="checkboxOptions"
                                @input="dataChanged($event, key)"
                            />
                            <FormulateInput
                                v-if="item.value.options.type === 'radio'"
                                type="radio"
                                :validation="item.value.required ? 'required' : ''"
                                :options="radioOptions"
                                @input="dataChanged($event, key)"
                            /> 
                        </div>
                    </template>
                    <!-- Dropdown -->
                    <template v-if="item.value.type === 'dropdown'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="dropdown-wrapper input-wrapper">
                            <FormulateInput
                                type="select"
                                :validation="item.value.required ? 'required' : ''"
                                :options="item.value.options.options"
                                :placeholder="labels.select_option"
                                @input="dataChanged($event, key)"
                            />
                        </div>
                    </template>
                    <!-- time -->
                    <template v-if="item.value.type === 'time'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="time-wrapper input-wrapper">
                            <FormulateInput
                                type="time"
                                :validation="item.value.required ? 'required|time' : 'time'"
                                :name="item.value.type"
                                :label="labels.time"
                                :validation-rules="{time: ({ value }) => timeValidate({value:value, itemType:item.value.type}, key)}"
                                @input="timeValidate({value:$event, itemType:item.value.type}, key)"
                            />
                            <div class="help-time" v-if="helpText">{{ helpText(item.value.type, item.value.options.min, item.value.options.max) }}</div>
                        </div>
                    </template>
                    <!-- date -->
                    <template v-if="item.value.type === 'date'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="date-wrapper input-wrapper">
                            <FormulateInput
                                type="date"
                                :validation="item.value.required ? 'required' : ''"
                                :name="item.value.type"
                                :label="labels.date"
                                :min="item.value.options.min"
                                :max="item.value.options.max"
                                @input="dataChanged( $event, key )"
                            />
                            <div class="help-time" v-if="helpText">{{ helpText(item.value.type, item.value.options.min, item.value.options.max) }}</div>
                        </div>
                    </template>
                    <!-- date-time -->
                    <template v-if="item.value.type === 'date-time'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="date-time-wrapper input-wrapper">
                            <div class="date-time-content">
                                <FormulateInput
                                    type="date"
                                    :validation="item.value.required ? 'required' : ''"
                                    :name="item.value.type"
                                    :label="labels.date"
                                    :min="computedDate.minDate"
                                    :max="computedDate.maxDate"
                                    error-behavior="live"
                                    @input="setDate($event)"
                                />
                                <FormulateInput
                                    type="time"
                                    :validation="item.value.required ? 'required|time' : 'time'"
                                    :name="item.value.type"
                                    :label="labels.time"
                                    :validation-rules="{time: ({ value }) => timeValidate({value:value, itemType:item.value.type}, key)}"
                                    @input="timeValidate({value:$event, itemType:item.value.type}, key)"
                                />
                            </div>
                            <div class="help-time" v-if="helpDateTimeText">{{ helpDateTimeText(item.value.options.min, item.value.options.max) }}</div>
                        </div>
                    </template>
                    <!-- File -->
                    <template v-if="item.value.type === 'file'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="file-wrapper input-wrapper">
                            <FormulateInput
                                type="file" 
                                :name="item.value.type" 
                                :label="labels.drag_drop_file_to_upload"
                                v-model="file"
                                :data-file-upload="labels.file_upload"
                                :validation="item.value.required ? 'required' : ''"
                                :change="fileChanged(file, key)" 
                            />
                        </div>
                    </template>
                    <!-- Media -->
                    <template v-if="item.type === 'media'">
                        <div class="item-media">
                            <img :src="item.value" alt="item.value">
                        </div>
                    </template>
                    <!-- displaying richtext -->
                    <template v-if="item.type === 'richtext'">
                        <div class="item-richtext">
                            <span v-html="item.value[currentLanguage]"></span>
                        </div>
                    </template>
                    <!-- displaying linear -->
                    <template v-if="item.value.type === 'linear'">
                        <h4 class="item-title">{{ item.value.title[currentLanguage] }}</h4>
                        <span class="item-text" v-html="item.value.text[currentLanguage]"></span>
                        <div class="content">
                            <p>{{ item.value.options.start.label[currentLanguage] }}</p>
                            <div class="numbers">
                                <p v-for="(linearItem, index) in getLinearOptions(item.value.options.start, item.value.options.end)" :key="index">
                                    <span
                                        :class="(item.value.options.selection == 'normal' && linearItem.key === localValue) || (item.value.options.selection == 'start' && linearItem.key <= localValue) ? 'item active' : 'item'"
                                        @click="changeLinearValue(linearItem.key, key)"
                                    >
                                        <span v-if="item.value && item.value.options && item.value.options.selection == 'normal'">{{ linearItem.label || linearItem.key }}</span>
                                        <span v-if="item.value && item.value.options && item.value.options.selection == 'normal'" class="dot"></span>
                                        <svg v-if="item.value && item.value.options && item.value.options.selection == 'start'" class="star" version="1.1" viewBox="0 0 121 121" xmlns="http://www.w3.org/2000/svg">
                                            <g transform="matrix(1.3333 0 0 -1.3333 0 2933.3)">
                                            <path d="m45.236 2200.1 13.971-29.788 31.241-4.7778-22.606-23.188 5.3367-32.74-27.943 15.458-27.943-15.458 5.3367 32.74-22.606 23.188 31.241 4.7778 13.971 29.788"/>
                                            </g>
                                        </svg>
                                    </span>
                                </p>
                            </div>
                            <p>{{ item.value.options.end.label[currentLanguage] }}</p>
                        </div>
                    </template>
                </div>
                <FormulateInput class="submit-button" type="button" :label="hasNext ? labels.next_page : labels.submit" @click="submitForm"/>
            </FormulateForm>
        </template>
        <template v-if="finished">
            <h4>{{ labels.your_answer_has_been_saved_thanks_have_a_nice_time }}</h4>
        </template>
    </div>
</template>
<script>
import VueFormulate from "@braid/vue-formulate";
import { mapActions } from 'vuex';
import Vue from "vue";
Vue.use(VueFormulate);

export default {
    name: 'CSForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        downloaded:{
            type:Function,
            default:()=>{}
        }
    },
    data() {
        return {
            section: 0,
            formId: null,
            started: false,
            finished: false,
            email: "",
            formValues: {},
            currentLanguage: "en",
            gridTableValues: {},
            date: "",
            time: "",
            file: "",
            localValue: '',
            labels: {
                enter_email_please: "Enter email please",
                start: "Start",
                submit: "Submit",
                please_enter_a_valid_email_address: "Please enter a valid email address",
                answer: "Answer",
                field_is_required: "Field is required",
                select_option: "Select option",
                time: 'Time',
                date: 'Date',
                date_time_range_between: "Date time range between",
                date_range_between: "Date range between",
                time_range_between: "Time range between",
                drag_drop_file_to_upload: "Drag drop file to upload",
                your_answer_has_been_saved_thanks_have_a_nice_time: "Your answer has been saved. Thanks, have a nice time!",
                please_select_value_for: "Please select value for",
                next_page: "Next page"
            },
            jsonData: '{}',
            loading: true,
            hasNext: false
        }
    },
    computed: {
        baseData() {
            console.log('this.jsonData')
            console.log(this.jsonData)
            console.log('this.jsonData')
            
            const baseInfo = JSON.parse(this.jsonData).base;
            return baseInfo;
        },
        dataContent() {
            const content = JSON.parse(this.jsonData).content;
            return content;
        },
        computedDate() {
            let minDate;
            let maxDate;

			let minTime;
			let maxTime;

            
            for (const key in this.dataContent) {
                const item = this.dataContent[key];
                if (item.value.type === 'date-time') {
                    if (item.value.options.min) {
                        minDate = item.value.options.min.split('T');
    
                        minTime = minDate[1]
                        minDate = minDate[0]
    
                        minTime = minTime.split(':')
                        minTime = minTime[0] + minTime[1]
                        if (minTime[0] == 0) {
                            minTime = minTime.substring(1)
                        }
                        minTime = parseFloat(minTime)
                    }
    
                    if (item.value.options.max) {
                        maxDate = item.value.options.max.split('T');
    
                        maxTime = maxDate[1]
                        maxDate = maxDate[0]
    
                        maxTime = maxTime.split(':')
                        maxTime = maxTime[0] + maxTime[1]
                        if (maxTime[0] == 0) {
                            maxTime = maxTime.substring(1)
                        }
                        maxTime = parseFloat(maxTime)
                    }
                }
                if (item.value.type === 'time') {
                    minTime = item.value.options.min.split(':');
                    maxTime = item.value.options.max.split(':');
                        
                    minTime = minTime[0] + minTime[1]
                    if (minTime[0] == 0) {
                        minTime = minTime.substring(1)
                    }
                    minTime = parseFloat(minTime)
                        
                    maxTime = maxTime[0] + maxTime[1]
                    if (maxTime[0] == 0) {
                        maxTime = maxTime.substring(1)
                    }
                    maxTime = parseFloat(maxTime)
                }
                if (item.value.options?.min === '') {
                    minDate = new Date().toLocaleDateString('en-CA');
                }
                if (item.value.options?.max === '') {
                    const tenYearsFromNow = new Date();
                    tenYearsFromNow.setFullYear(tenYearsFromNow.getFullYear() + 10);
                    maxDate = tenYearsFromNow.toLocaleDateString('en-CA');
                }
            }
            return {minDate: minDate, maxDate: maxDate, minTime: minTime, maxTime: maxTime}
        },
        radioOptions(){
            let out = {};
            for (const key in this.dataContent) {
                const item = this.dataContent[key];
                if (item.value.type === 'select' && item.value.options.type === 'radio') {
                    for (const id in item.value.options.options) {
                        const element = item.value.options.options[id];
                        out[`${id}`] = `${element}`
                    }
                }
            }
            return out
        },
        checkboxOptions() {
            let out = {};
            for (const key in this.dataContent) {
                const item = this.dataContent[key];
                if (item.value.type === 'select' && item.value.options.type === 'checkbox') {
                    for (const id in item.value.options.options) {
                        const element = item.value.options.options[id];
                        out[`${id}`] = `${element}`
                    }
                }
            }
            return out
        },
    },
    methods:{
        ...mapActions('form', ['clientRender', 'clientSubmit']),
        ...mapActions('notification', ['addNotification']),
        getFormData() {
            this.loading = true;
            let id = 1
            if(this.id) id = this.id
            if(this.$route?.params?.id) id = this.$route?.params?.id

            let requestData = {
                form_id: id,
                section: this.section
            };

            this.clientRender(requestData).then((res) => {
                if (res.success) {
                    this.jsonData = JSON.stringify(res.data);
                    this.formValues = res.data
                    this.formId = this.formValues.id
                    this.hasNext = res.data.hasNext;

                    if (!this.email.length && !res.emailRequired) {
                        this.startQuiz({email: `submit_${Date.now()}`, skipCheck: true})
                    }
                } else if (res.error) {
                    console.log('Errors during form load:', res.error);
                }

                this.loading = false;    
            }).catch((err) => console.log(err));
        },
        startQuiz(data) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            let emailValid = true
            
            if (!data.skipCheck) emailValid = emailPattern.test(data.email);

            if (emailValid) {
                this.started = true;
                this.email = data.email
            }
        },
        gridChanged( values, key ) {
            if ( values.inputType === 'radio' ) {
                if ( values.rowDependence ) this.gridTableValues[values.rowKey] = values.columnKey
                else this.gridTableValues[values.columnKey] = values.rowKey
            }
            this.dataChanged(this.gridTableValues, key)
        },
        fileChanged( value, key ) {
            this.dataChanged( value.fileList?.[0] || '', key );
        },
        dataChanged( value, key ) {
            this.formValues.content[key].answer = value
        },
        changeLinearValue(value, key) {
            this.localValue = value
            this.dataChanged(value, key)
        },
        getLinearOptions(start, end) {
            let output = []
            if (
                (end && end.number !== undefined && typeof end.number === 'number') &&
                (start && start.number !== undefined && typeof start.number === 'number')
            ) {
                for (let i = start.number; i <= end.number; i++) {
                    output.push({ label: i, key: i })
                }
            }
            return output
        },
        helpText(type, min, max){
            if(type === 'time') {
                return min && max ? `${this.labels.time_range_between} ${min} - ${max}` : ''
            }
            return min && max ? `${this.labels.date_range_between} ${min} - ${max}` : ''
        },
        helpDateTimeText(min, max){
            if (min && max) {
				return `${this.labels.date_time_range_between} ${min} - ${max}`
			}
			else if (min) {
				return `Min date ${min}`
			}
			else if (max) {
				return `Max date ${max}`
			}
			else {
				return ''
			}
        },
        setTime(data, key) {
            this.dataChanged(data, key);
        },
        setDate(date) {
            this.date = date;
        },
        setTimeDate(time, key) {
            if (this.date !== '') {
                let dataTime = {
                    date: this.date,
                    time: time
                }
                this.dataChanged(dataTime, key)
            }
        },
        timeValidate({value, itemType}, itemKey) {
			let selectedTime = value.split(':')
			selectedTime = selectedTime[0] + selectedTime[1]
			if (selectedTime[0] == 0) {
				selectedTime = selectedTime.substring(1)
			}
			selectedTime = parseFloat(selectedTime)
            if (this.computedDate.minTime <= selectedTime && this.computedDate.maxTime >= selectedTime) {
                if (itemType === 'date-time') this.setTimeDate(value, itemKey)
                if (itemType === 'time') this.setTime(value, itemKey)
                return true
            } else if (this.computedDate.minTime <= selectedTime && this.computedDate.maxTime === undefined)  {
                if (itemType === 'date-time') this.setTimeDate(value, itemKey)
                if (itemType === 'time') this.setTime(value, itemKey)
                return true
            } else if (this.computedDate.maxTime >= selectedTime && this.computedDate.minTime === undefined)  {
                if (itemType === 'date-time') this.setTimeDate(value, itemKey)
                if (itemType === 'time') this.setTime(value, itemKey)
                return true
            } else {
                if (itemType === 'date-time') this.setTimeDate('', itemKey)
                if (itemType === 'time') this.setTime('', itemKey)
                return false
            }
		},
        submitForm() {
            let warnings = []
            let payload = {}
    
            let id = 1
            if(this.id) id = this.id
            if(this.$route?.params?.id) id = this.$route?.params?.id

            for ( const key in this.formValues.content ) {
                if (Object.hasOwnProperty.call(this.formValues.content, key)) {
                    let element = this.formValues.content[key]
                    if ( element.answer ) {
                        payload[key] = {
                            id: element.id,
                            type: element.value.type,
                            answer: element.answer
                        }
                    }
                    if ( element.value.required ) {
                        if (
                            !element.answer ||
                            (Array.isArray(element.answer) && !element.answer.length) ||
                            (element.value.type === 'richtext' && element.answer === '<p></p>') ||
                            (element.value.type === 'file' && (!element.answer || !(element.answer instanceof File)))
                        ) {
                            warnings.push(`${this.labels.please_select_value_for}: ${element.value.title[this.currentLanguage]}`)
                        }
                    }
                }
            }
            // this.addNotification({variant: 'warning', msg: warnings });
            if ( warnings.length ) alert(warnings)
            else {
                let requestData = {
                    form_id: id,
                    email: this.email,
                    section: this.formValues.id,
                    payload
                };

                this.clientSubmit(this.$prepareForm(requestData)).then( res => {
                    if ( res.success ) {                        
                        if ( res.data.hasNext ) {
                            this.section++
                            this.getFormData();
                        } else {
                            this.finished = true;

                            if(typeof window !== 'undefined'){
                                if(window.parent){
                                    window.parent.postMessage({
                                        'downloaded': true
                                    }, "*");
                                }
                            }
                        }
                        this.addNotification( { variant: 'success', msg: 'success' } );
                    } else if ( res.error ) {
                        this.addNotification( { variant: 'danger', msg: res.error } );
                    }
                } ).catch( err => console.log( err ) );
            }
        },
        handleResult(a){
            if(a.data.downloaded) this.downloaded()
        }
    },
    watch: {
        value() {
            this.localValue = this.value
        }
    },
    mounted(){
        if(typeof window != 'undefined'){
            if (window.addEventListener) {
                window.addEventListener("message", this.handleResult, false);        
            } else if (window.attachEvent) {
                window.attachEvent("onmessage", this.handleResult, false);
            }
            this.host = window.location.host
        }

        this.getFormData();

    },
    destroyed(){
        if(typeof window != 'undefined') {
            if (window.removeEventListener) {
                window.removeEventListener("message", this.handleResult, false);        
            } else if (window.detachEvent) {
                window.detachEvent("onmessage", this.handleResult, false);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.CS-form {
    width: 100%;
    background-color: #fff;
    box-shadow: 5px 5px 10px 0 rgba(0,0,0,.2);
    padding: 25px;
}
h4.form-title {
    font-size: 24px;
    margin-bottom: 20px;
}
h4.finished {
    margin-bottom: 0px;
    text-align: center;
}
h4{
    font-size: 22px;
    margin-bottom: 10px;
}
span.form-text {
    font-size: 16px;
}
::v-deep .formulate-input-errors {
    list-style: none;
    padding: 0;
    .formulate-input-error {
        color: #960505;
        font-size: 0.8rem;
        line-height: 1.5;
        margin-bottom: 0.25em;
    }
}

.input-wrapper {
    width: 100%;
}
.form{
    .email-input-wrapper ::v-deep{
        .formulate-input-element{
            max-width: 100%;
        }
        input {
            width: 100%;
            padding: 10px;
            outline: none;
            border: 1px solid #999;
            border-radius: 0;
            &:focus {
                border-color: #CCD5AE;
            }
        }
    }
}
.submit-button ::v-deep button{
    margin-top: 20px !important;
    transition: all 500ms ease-out !important;
    background: #CCD5AE !important;
    color: #333 !important;
    font-weight: 500 !important;
    border: 1px solid #CCD5AE !important;
    min-width: 130px !important;
    padding: 15px !important;
    border-radius: 0px !important;
    min-width: 150px !important;
    display: flex !important;
    justify-content: center !important;
    &:hover {
        border-color: #CCD5AE !important;
        background: transparent !important;
        color: #333 !important;
    }
}
.form-item {
    margin-bottom: 20px;
    border-bottom: 1px solid #999;
    padding-bottom: 20px;
    .item-text {
        font-size: 16px;
        font-weight: 400;
    }
    .text-input-wrapper ::v-deep {
        .formulate-input-element{
            max-width: 100%;
        }
        input {
            width: 100%;
            padding: 10px;
            outline: none;
            border: 1px solid #999;
            border-radius: 0;
            &:focus {
                border-color: #CCD5AE;
            }
        }
    }
    //richtext
    .text-area-wrapper ::v-deep {
        .formulate-input-element.formulate-input-element--textarea {
            max-width: 100%;
        }
        textarea {
            min-height: 135px;
            width: 100%;
            font-size: 14px;
            padding: 15px 10px;
            border: 1px solid #999;
            outline: none;
            resize: none;
            border-radius: 0;
            &:focus {
                border-color: #CCD5AE;
            }
        }
    }
    // grid 
    .table-wrapper {
        width: 100%;
        overflow-x: auto;

        .grid-table {
            min-width: max-content;
            ::v-deep .formulate-input-wrapper {
                justify-content: center;
                label {
                    margin-bottom: 0;
                    margin-top: 3px;
                }
            }
            thead td {
                border-top: none;
                min-width: 150px;
            }
            th,td:not(thead td) {
                border: 1px solid #999;
                text-align: center;
                min-width: 150px;
            }
        }
    }
    //select
    .select-wrapper ::v-deep {
        .formulate-input-element.formulate-input-element--group.formulate-input-group {
            max-width: 100%;
        }
        .formulate-input-group-item {
            padding: 10px;
            border: 1px solid #999;
            margin-bottom: 10px;
            .formulate-input-wrapper {
                display: flex;
                align-items: center;
                .formulate-input-label {
                    padding-right: 10px;
                    margin-bottom: 0;
                }
                .formulate-input-element {
                    max-width: 100%;
                    min-width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    label {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    //dropdown
    .dropdown-wrapper ::v-deep {
        .formulate-input-element.formulate-input-element--select {
            max-width: 100%;
            position: relative;
            &::before {
                content: "";
                width: 0;
                height: 0;
                border: 0.3em solid transparent;
                border-top-color: #999;
                border-bottom-width: 0;
                top: 50%;
                margin-top: -0.1em;
                right: 1em;
                position: absolute;
            }
        }
        select {
            appearance: none;
            width: 100%;
            border: 1px solid #999;
            outline: none;
            padding: 10px;
            border-radius: 0;
            &:focus {
                border-color: #CCD5AE;
            }
        }
    }
    // time
    .time-wrapper ::v-deep {
        .formulate-input {
            margin-bottom: 10px;
        }
        .formulate-input-wrapper {
            display: flex;
            align-items: center;
            label {
                margin-bottom: 0;
                margin-right: 10px;
            }
            .formulate-input-element.formulate-input-element--time {
                max-width: 100%;
                width: 100%;
                margin-bottom: 0.25rem;
                input {
                    width: 100%;
                    border: 1px solid #999;
                    outline: none;
                    padding: 10px;
                    border-radius: 0;
                    &:focus {
                        border-color: #CCD5AE;
                    }
                }
            }
        }
    }
    .help-time {
        color: #6d6d6d;
        font-size: 0.7rem;
        font-weight: 400;
        line-height: 1.5;
        margin-top: 0.1em;
        margin-bottom: 0.25em;
    }
    //date
    .date-wrapper ::v-deep {
        .formulate-input {
            margin-bottom: 10px;
        }
        .formulate-input-wrapper {
            display: flex;
            align-items: center;
            label {
                margin-bottom: 0;
                margin-right: 10px;
            }
            .formulate-input-element {
                max-width: 100%;
                width: 100%;
                margin-bottom: 0.25rem;
                input {
                    width: 100%;
                    border: 1px solid #999;
                    outline: none;
                    padding: 10px;
                    border-radius: 0;
                    &:focus {
                        border-color: #CCD5AE;
                    }
                }
            }
        }
    }
    //date-time
    .date-time-wrapper ::v-deep {
        .formulate-input {
            margin-bottom: 10px;
        }
        .date-time-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            @media (max-width: 500px) {
                flex-direction: column;
                row-gap: 20px;
            }
            .formulate-input {
                width: 49%;
                @media (max-width: 500px) {
                    width: 100%;
                }
            }
            .formulate-input-wrapper {
                display: flex;
                align-items: center;
                width: 100%;
                label {
                    margin-bottom: 0;
                    margin-right: 10px;
                }
                .formulate-input-element {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 0.25rem;
                    input {
                        width: 100%;
                        border: 1px solid #999;
                        outline: none;
                        padding: 10px;
                        border-radius: 0;
                        &:focus {
                            border-color: #CCD5AE;
                        }
                    }
                }
            }
        }
    }
    .item-media {
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
            height: auto;
            max-height: 450px;
        }
    }
    .content {
        display: flex;
        justify-content: space-between;
        color: #999;
        .numbers {
            display: flex;
            overflow: auto;
            margin: -10px 15px 0;
            .item {
                @extend %item;
                color: #999;
                &.active {
                    @extend %item;
                    color: #CCD5AE;
                    .dot {
                        @extend %dot;
                        background-color: #CCD5AE;
                    }
                    svg {
                        fill: #999;
                        width: 30px;
                    }
                }
                &:hover {
                    color: #CCD5AE;
                    .dot {
                        background-color: #CCD5AE;
                    }
                }
                .dot {
                    @extend %dot;
                    background-color: #999;
                }
            }
        }
    }
    %item {
        position: relative;
        margin: 0 10px 0 10px;
        cursor: pointer;
        text-decoration: none;
        transition: 0.5s;
    }

    %dot {
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        top: 25px;
        left: 0;
        right: 0;
        margin: auto;
        transition: 0.5s;
    }
}

</style>
